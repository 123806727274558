.header-container{
    min-height: 10vh;
    display: flex;
    justify-content: center;
    background-color: rgb(241, 45, 45);
    color: white;
}

.home-container{
    align-items: center;
    min-height: 80vh;
    display: flex;
    justify-content: center;
    background-color: rgb(255, 255, 255);
}

.footer-container{
    min-height: 10vh;
    display: flex;
    justify-content: center;
    background-color: rgb(241, 45, 45);
    color: white;
}

.poke-card{
    border: 1px solid rgb(192, 192, 192);
    padding: 20px;
    border-radius: 10px;
}

.poke-card:hover{
    box-shadow: 0px 0px 10px rgb(129, 129, 129);
}

.pokemon-img-size{
    width: 180px;
}